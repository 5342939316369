<template>
    <div class="partner-area">
        <div class="container">
            <h3>More that 1.5 million businesses and organizations use Vibion</h3>

            <div class="partner-inner">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-1.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover1.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-2.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover2.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-3.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover3.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-4.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover4.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-5.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover5.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-6.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover6.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-7.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover7.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-8.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover8.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-9.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover9.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-10.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover10.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-11.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover11.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-12.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover12.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-13.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover13.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-14.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover14.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-15.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover15.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-16.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover16.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-17.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover17.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                        <a href="#">
                            <img src="../../assets/images/partner/partner-18.png" alt="partner">
                            <img src="../../assets/images/partner/partner-hover18.png" alt="partner">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Partner'
    }
</script>