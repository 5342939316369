<template>
    <div class="account-create-area">
        <div class="container">
            <div class="account-create-content">
                <h2>Apply for an account in minutes</h2>
                <p>Get your Vibion account today!</p>
                <a href="#" class="btn btn-primary">Get Your Account</a>
            </div>
        </div>
    </div> 
</template>

<script>
    export default {
        name: 'AccountCreate'
    }
</script>