<template>
    <div class="features-area ptb-70 bg-f6f4f8">
        <div class="container">
            <div class="section-title">
                <h2>Our Features</h2>
                <div class="bar"></div>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <!-- <i class="flaticon-settings"></i> -->
                            <img class="flaticon-settings" src="../../assets/images/image (3).png"/>
                        </div>

                        <h3>Digital Banking</h3>
                        <p>We offer access to the same legacy bank account capabilities associated with traditional banking by connecting your digital user database to Bankeasy for deposits, transfers and withdrawals and more.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-f78acb">
                            <!-- <i class="flaticon-envelope-of-white-paper"></i> -->
                            <img class="flaticon-settings" src="../../assets/images/image (2).png"/>
                        </div>

                        <h3>Cards & Payments</h3>
                        <p>Offer customized cards and transact across multiple mediums and offer the same service to your customers with seamless integration to Bankeasy custom card issuance services.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-cdf1d8">
                            <!-- <i class="flaticon-menu"></i> -->
                            <img class="flaticon-settings" src="../../assets/images/image (4).png"/>
                        </div>

                        <h3>KYC Services</h3>
                        <p>Digital platforms need to be certain their customers are who they say they are through the relevant customer checks. Our KYC/KYB checks provide fintech companies with authorization on customer profiles before payments.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-c679e3">
                            <!-- <i class="flaticon-info"></i> --> 
                            <img class="flaticon-settings" src="../../assets/images/image (5).png"/>
                        </div>

                        <h3>Lending and More</h3>
                        <p>Doing more with less is the name of the game in this economic climate. Ride on Bankeasy's value added services bundles to offer your customers Loan schemes, Insurance packages and pension bundles tailored to fit them.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-eb6b3d">
                            <!-- <i class="flaticon-cursor"></i> -->
                            <img class="flaticon-settings" src="../../assets/images/image (6).png"/>
                        </div>

                        <h3>Digital Assets</h3>
                        <p>Start the next wave of fintech innovation by leveraging our digital banking assets for your product development.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <!-- <i class="flaticon-alarm"></i> -->
                            <img class="flaticon-settings" src="../../assets/images/image (1).png"/>
                        </div>

                        <h3>Value Added Services</h3>
                        <p>Offer customers options to top up phone airtime, and internet data, Pay electricity bills, Government tax, School fees, and renew TV subscriptions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FeaturesTwo'
    }
</script>