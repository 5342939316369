<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-7.jpg" alt="image">
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">Automated Accounting</span>
                        <h2>Save 24 hours per week on accounting</h2>
                        <div class="bar"></div>
                        <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <ul class="features-list">
                            <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                            <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                        </ul>

                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesFour'
    }
</script>